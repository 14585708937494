import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const gatsbyData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          lang_configs {
            en {
              dir
              menu {
                prefix
                items {
                  label
                  path
                  external
                  no_pref
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout
      dir={gatsbyData.site.siteMetadata.lang_configs.en.dir}
      menu={gatsbyData.site.siteMetadata.lang_configs.en.menu}
      title="Not Found"
      description=""
    >
      <h2>Ooops! Wrong Turn!</h2>
    </Layout>
  )
}
